
import React from 'react';

import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import visa from "images/vis.png";
import master from "images/mastercard.png";
interface PaymentFormProps {
  control: any; // Replace with the type for your form control
  errors: any; // Replace with the type for your form errors
  handleAddCard: () => void;
  // setValue:any;
  validateCardNumber: (value: string) => true | string; // Replace with your validation function type
  validateData: (value: string) => true | string;  // Replace with your validation function type
}

const PaymentForm: React.FC<PaymentFormProps> = ({
    control,
    errors,
   
    handleAddCard,
    validateCardNumber,
    validateData,
}) => {
  console.log(
    control,
    errors,

    handleAddCard,
    validateCardNumber,
    validateData,
    "enteringpayment"
  );
  const [cardType, setCardType] = useState<"Visa" | "MasterCard" | "">("");
 const cardTypeIcon = {
   Visa: visa,
   MasterCard: master,
  };
  const detectCardType = (cardNumber: string) => {
     const firstDigit = cardNumber.slice(0, 1);
     const firstTwoDigits = cardNumber.slice(0, 2);
     if (firstDigit === "4") return "Visa";
     if (["51", "52", "53", "54", "55"].includes(firstTwoDigits))
       return "MasterCard";
     return ""; // Default for unknown card type
   };
 
    return (
      <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
        {/* <div className="max-w-lg">
          <Label className="text-sm">Card number</Label>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "Card number is required",
              validate: validateCardNumber,
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                type="tel"
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            )}
          />
          {errors.cardNumber && (
            <span className="text-red-500">{errors.cardNumber.message}</span>
          )}
        </div> */}
        <div className="max-w-lg">
          <Label className="text-sm">Card Number</Label>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "Card Number is required",
              validate: validateCardNumber,
            }}
            render={({ field }) => (
              <div className="relative">
                <Input
                  {...field}
                  className="mt-1.5 pr-10"
                  type="tel"
                  onInput={(e: any) => {
                    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    field.onChange(value); // Update form value with raw digits (no spaces)

                    // Format the value with spaces between every 4 digits
                    const formattedValue = value.replace(
                      /(\d{4})(?=\d)/g,
                      "$1 "
                    );
                    e.target.value = formattedValue;

                    const detectedCardType = detectCardType(value); // Detect card type
                    setCardType(detectedCardType); // Set card type for logo
                  }}
                />

                {/* Render the card logo */}
                {cardType && (
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                    <img
                      src={cardTypeIcon[cardType]}
                      alt={`${cardType} logo`}
                      className="w-12"
                    />
                  </div>
                )}
              </div>
            )}
          />
          {errors.cardNumber && (
            <span className="text-red-500">{errors.cardNumber.message}</span>
          )}
        </div>
        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
            <Label className="text-sm">First Name</Label>
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: "First Name is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => e.target.value}
                />
              )}
            />
            {errors.firstname && (
              <span className="text-red-500">{errors.firstname.message}</span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm">Last Name</Label>
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: "Last Name is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => e.target.value}
                />
              )}
            />
            {errors.lastname && (
              <span className="text-red-500">{errors.lastname.message}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="sm:w-2/3">
            <Label className="text-sm">Expiration Date (MM/YY)</Label>
            <Controller
              name="expirationDate"
              control={control}
              rules={{
                required: "date is Required",
                validate: validateData,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  type="tel"
                  onInput={(e: any) => {
                    let inputValue = e.target.value.replace(/\D/g, "");
                    if (inputValue.length === 1 && inputValue > 1) {
                      inputValue = "0" + inputValue;
                    }
                    if (inputValue.length >= 2) {
                      let month = inputValue.slice(0, 2);
                      if (parseInt(month, 10) > 12) {
                        month = "12";
                      }
                      e.target.value = `${month}/${inputValue.slice(2, 4)}`;
                    } else {
                      e.target.value = inputValue;
                    }
                  }}
                />
              )}
            />

            {errors.expirationDate && (
              <span className="text-red-500">
                {errors.expirationDate.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm">CVC</Label>
            <Controller
              name="CVC"
              control={control}
              rules={{
                validate: (values) => {
                  const cleaned = values.replace(/\D/g, "");
                  const valid = cleaned.length === 3 || cleaned.length === 4;
                  if (!valid) {
                    return "CVC is required";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <Input
                  type="tel" // Change to "tel" to bring up the numeric keypad
                  {...field}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, "");
                    if (value.length > 4) {
                      value = value.slice(0, 4);
                    }
                    field.onChange(value);
                  }}
                  className="mt-1"
                />
              )}
            />
            {errors.CVC && (
              <span className="text-red-500">{errors.CVC.message}</span>
            )}
          </div>
        </div>
      </div>
    );
};

export default PaymentForm