import { useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import Card from "shared/Card";
import { useForm } from "react-hook-form";
import PaymentForm from "../PageCheckout/PaymentForm";
import BillingAddressForm from "../PageCheckout/BillingAddress";
import { postPaymentData } from "services/HomeService";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import appConfig from "configs/app.config";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { getHeaders } from "@/services/BaseService";
interface Cards {
  payment: {
    creditCard: {
      cardNumber: string;
      cardType: string;
      expirationDate: string;
      
    };
  };
  billTo: {
    customerId: string;
    firstName: string;
    lastName: string;
    profileId: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  customerPaymentProfileId: string;
}

type CardData = {
  cardNumber: string;
  cardType: string;
  expirationDate: string;
  firstName: string;
  lastName: string;
  profileId: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

const AccountSavelist = () => {
  const [user, setUser] = useState(localStorage.getItem("user")??"");
  const [auth, setAuth] = useState<Cards[]>([]);
  const [phonee, setPhone] = useState("");
  const [useremail, setUseremail] = useState("");
  const [cards, setCards] = useState<CardData[]>([]);
  const [showCardField, setShowCardField] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const userDataString = localStorage.getItem("userData");
  const SignupData = localStorage.getItem("SignupData");
  const CustomerAdded = localStorage.getItem("customerAdded")
  const [billingCity,setBillingCity] = useState('');
  const [billingAddressInfo, setBillingAddressInfo] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const addedPayments = localStorage.getItem("addedPayments");
  
  const Info=localStorage.getItem("CustomerInfo");
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const handleEdit = async (data: any) => {
    console.log("Edit button clicked",data);
    setEditData(data);
     setShowCardField(false);
    setFormVisible(true);
    setBillingCity(data.city);
    setBillingAddressInfo(data.address);
    setBillingZip(data.zip);
    setBillingState(data.state);
    setBillingCountry("USA");
    // Populate form fields with existing data
    setValue("firstname", data.firstName);
    setValue("lastname", data.lastName);
    // setValue("phone", data.phone);
    setValue("address1", data.address);
    setValue("address2", data.address2);
    setValue("city", data.city);
    setValue("state", data.state);
    setValue("zip", data.zip);
    setValue("country", data.country);
    setValue("cardNumber", data.cardNumber);
    setValue("expirationDate", data.expirationDate);
    setValue("CVC", data.CVC);
    setValue("profileId", data.profileId);
  
    // const apiData = {
    //   billingInfo: 
    //     {
    //       firstName: data.firstname,
    //       lastName: data.lastname,
    //       // email: useremail,
    //       phone: cleanPhoneNumber(phonee),
    //       address: data.address1+data.address2,
    //       city: data.city,
    //       state: data.state,
    //       zipCode: data.zip,
    //       country: data.country,
    //       addressType: "Billing",
    //     },
      
    //   paymentInfo: 
    //     {
    //        customerProfileId: user,
    //        paymentProfileId: data.profileId,
    //       cardNumber: data.cardNumber,
    //       exprDate: data.expirationDate,
    //       cardCode: data.CVC,
    //     },
     
    //   defaultPaymentProfile: false,
    // };
  };

  const handleDelete = async (profileId: string) => {
     const userConfirmed = window.confirm(
       "Are you sure you want to delete the payment method?"
     );
     if (!userConfirmed) return; 
    const apiData = {
      customerProfileId: user,
      paymentProfileId: profileId,
      
    }
    console.log("Delete button clicked");
    const baseurl = appConfig.apiPrefix;
    const apiurl = `/onlineOrder/customer/payment`;
    console.log(apiData, "apiData");
    const responsePayment=await axios.delete(baseurl + apiurl, {
      data: apiData,
      headers: {
        MID: merchantname,
        Timezone: getCurrentTimezone(),
      },
    });
    window.location.reload();
  };

  const handleAddCard = () => {
    setFormVisible(false);
    reset();
    setShowCardField(true);
  };

  useEffect(() => {
    
    console.log(SignupData ,userDataString  ,"userDataStringaaaa");
    console.log(localStorage, "userDataString");
    const fetchDataFromAPI = async () => {
      try {
        if (userDataString && Info && !SignupData) {
          const response = JSON.parse(userDataString);
          const responsetwo = JSON.parse(Info);
          console.log(response.profile,addedPayments, "userDataString");
          console.log(JSON.parse(userDataString), "userDataString");
          if (addedPayments) {
              console.log(response?.paymentProfiles,addedPayments, "addedPayments");
             setUser(response?.customerProfileId);
            setAuth(JSON.parse(addedPayments));

            setPhone(responsetwo.phone);
            setUseremail(responsetwo.email);
          }
          else if (response?.paymentProfiles === null) {
            console.log(response?.paymentProfiles, "addedPayments");
            setPhone(responsetwo.phone);
            setUseremail(responsetwo.email);
          } else {
           
            setUser(response?.customerProfileId);
            setAuth(response?.paymentProfiles);

            setPhone(responsetwo.phone);
            setUseremail(responsetwo.email);
          }

          if (response["AuthNet Profile Info "]?.profile?.paymentProfiles) {
            const cardsData: {
              // profileId: string;
              cardNumber: string;
              cardType: string;
              expirationDate: string;
              firstName: string;
              lastName: string;
              profileId: string;
              address: string;
              city: string;
              state: string;
              zip: string;
            }[] = auth.map((card) => ({
              cardNumber: card.payment.creditCard.cardNumber,
              cardType: card.payment.creditCard.cardType,
              expirationDate: card.payment.creditCard.expirationDate,
              firstName: card.billTo.firstName,
              lastName: card.billTo.lastName,
              profileId: card.customerPaymentProfileId,
              address: card.billTo.address,
              city: card.billTo.city,
              state: card.billTo.state,
              zip: card.billTo.zip,
            }));
            setCards(cardsData);
          }
        }
        else if (SignupData && userDataString && CustomerAdded) {
          const response = JSON.parse(SignupData);
          const responsetwo = JSON.parse(userDataString);
          const responsethree = JSON.parse(CustomerAdded);
          
          if (responsetwo["AuthNet Profile Info "]?.profile?.paymentProfiles) {
            const cardsData: {
              cardNumber: string;
              cardType: string;
              expirationDate: string;
              firstName: string;
              lastName: string;
              profileId: string;
              address: string;
              city: string;
              state: string;
              zip: string;
            }[] = auth.map((card) => ({
              cardNumber: card.payment.creditCard.cardNumber,
              cardType: card.payment.creditCard.cardType,
              expirationDate: card.payment.creditCard.expirationDate,
              firstName: card.billTo.firstName,
              lastName: card.billTo.lastName,
              profileId: card.customerPaymentProfileId,
              address: card.billTo.address,
              city: card.billTo.city,
              state: card.billTo.state,
              zip: card.billTo.zip,
            }));
            console.log(
              responsetwo["AuthNet Profile Info "]?.profile?.paymentProfiles[0],cardsData,
              "userdataa11"
            );
            setCards(cardsData);
          }
          // console.log(
          //   responsetwo,
          //   response.customerPaymentProfileIdList.numericString[0],
          //   responsetwo["AuthNet Profile Info "].profile.paymentProfiles[0]
          //     ?.billTo.email,
          //   "userdataa11"
          // );
          console.log(
            response,
            responsetwo,
            response?.authNetProfileId,
            "checkkkkkk"
          );
          setUseremail(
            responsethree.email
          );
          setPhone(
            responsethree.phone
          );
          if (response?.authNetProfileId != null) {
            setUser(response.authNetProfileId);
            setAuth(
              responsetwo["AuthNet Profile Info "]?.profile?.paymentProfiles
            );
          }
        
        }
        else if (Info && !SignupData) {
          console.log("signup")
          const response = JSON.parse(Info);
          setPhone(response.phone);
          setUseremail(response.email);
        }
        else if (CustomerAdded) {
          console.log("signup");
          const response = JSON.parse(CustomerAdded);
          setPhone(response.phone);
          setUseremail(response.email);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (!user || !auth ||!useremail) {
      console.log("fetching data");
      fetchDataFromAPI();
    }
  }, []);
console.log(auth,"111111111111111111")
  useEffect(() => {
    if (auth) {
      const cardsData: {
        cardNumber: string;
        cardType: string;
        expirationDate: string;
        firstName: string;
        lastName: string;
        profileId: string;
        address: string;
        city: string;
        state: string;
        zip: string;
      }[] = auth.map((card) => ({
        cardNumber: card.payment.creditCard.cardNumber,
        cardType: card.payment.creditCard.cardType,
        expirationDate: card.payment.creditCard.expirationDate,
        firstName: card.billTo.firstName,
        lastName: card.billTo.lastName,
        profileId: card.customerPaymentProfileId,
        address: card.billTo.address,
        city: card.billTo.city,
        state: card.billTo.state,
        zip: card.billTo.zip,
      }));
      setCards(cardsData);
    }
  }, [auth]);

 const validatecardNumber = (values: any) => {
   const cardNumber = values.replace(/\s/g, ""); // Remove all spaces

   if (!cardNumber) {
     return "Card number is required";
   } else if (cardNumber.length < 13) {
     return "Card number must be at least 13 digits";
   } else if (cardNumber.length > 16) {
     return "Card number must be no more than 16 digits";
   } else {
     return true;
   }
 };
  const validateDate = (value: any) => {
    if (!/^\d{2}\/\d{2}$/.test(value)) {
      return "Expiration date must be in MM/YY format";
    }
    const [month, year] = value.split("/").map(Number);
    if (month < 1 || month > 12) {
      return "Expiration month must be between 01 and 12";
    }
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return "Invalid Expiration date";
    } else {
      return true;
    }
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      return phoneNumber.replace(/\D/g, "");
    } else {
      return null;
    }
  };
const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      profileId: "",
      cardNumber: "",
      firstname: "",
      lastname: "",
      expirationDate: "",
      CVC:"",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "USA",
      zip: "",
    },
  });
 
  const handleSaveCard = async (data: any) => {
    console.log(user, data, "dataaaaa");
     const cardNumber = data.cardNumber.replace(/\s/g, ""); 
    const apiData = {
      billingInfo: {
        firstName: data.firstname,
        lastName: data.lastname,
        email: useremail,
        phone: cleanPhoneNumber(phonee),
        address: data.billingAddress.address1,
        city: data.billingAddress.city,
        state: data.billingAddress.state,
        zipCode: data.billingAddress.zip,
        country: data.billingAddress.country,
        addressType: "Billing",
      },

      paymentInfo: {
        customerProfileId: user,
        cardNumber: cardNumber,
        exprDate: data.expirationDate,
        cardCode: data.CVC,
      },

      defaultPaymentProfile: false,
    };
    const apiDataa = {
      billingInfo: {
        firstName: data.firstname,
        lastName: data.lastname,
        // email: useremail,
        phone: cleanPhoneNumber(phonee),
        address: data.billingAddress.address1 + data.billingAddress.address2,
        city: data.billingAddress.city,
        state: data.billingAddress.state,
        zipCode: data.billingAddress.zip,
        country: data.billingAddress.country,
        addressType: "Billing",
      },

      paymentInfo: {
        customerProfileId: user,
        paymentProfileId: data.profileId,
        cardNumber: cardNumber,
        exprDate: data.expirationDate,
        cardCode: data.CVC,
      },

      defaultPaymentProfile: false,
    };
    const sequreCardNumber = data.cardNumber.slice(-4);

    try {
      if (formVisible) {
        const baseurl = appConfig.apiPrefix;
        const apiurl = `/onlineOrder/customer/payment/update`;
       
        const responsePayment = await axios.post(baseurl + apiurl, apiDataa, {
          headers: {
            MID: merchantname,
            Timezone: getCurrentTimezone(),
          },
        });
         console.log(responsePayment.data.messages.resultCode, "apiData");
        if (responsePayment.data.messages.resultCode === "OK") {
          setFormVisible(false);
          reset();
          window.location.reload();
        }
        else {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-red-100 dark:bg-red-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-red-500/50 dark:ring-red-500/10 text-red-900 dark:text-red-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Failed to Save Card
                </p>
                <div className="border-t border-red-200 dark:border-red-700 my-4" />
                We're experiencing difficulties saving your card. Please try
                another card or try again later.
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
        }
        
        
      }
      else {
        const baseurl = appConfig.apiPrefix;
        const apiurl = `/onlineOrder/customer/payment`;
        console.log(apiData, "apiData");
        const responsePayment = await axios.post(baseurl + apiurl, apiData, {
          headers: {
            MID: merchantname,
            Timezone: getCurrentTimezone(),
          },
        });
        console.log(responsePayment.data["response:"], user,"responsePayment");
      
        if (!user && responsePayment.data["response:"].customerProfileId) {
          const newId = responsePayment.data["response:"];
          console.log(
            responsePayment.data["response:"].customerProfileId,
            responsePayment.data["response:"],
            newId,
            newId?.customerProfileId,
            "userprofileid"
          );
          localStorage.setItem(
            "user",
            responsePayment.data["response:"].customerProfileId
          );
          setUser(responsePayment.data["response:"].customerProfileId);
          // setAuth(response.profile.paymentProfiles);
        }
      
        setShowCardField(false);

        reset();
        window.location.reload();
      }
    }
    catch (error) {
      console.log(error,"errorrrrr");
      toast.custom(
        (t) => (
          <Transition
            appear
            show={t.visible}
            className="p-4 max-w-md w-full bg-red-100 dark:bg-red-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-red-500/50 dark:ring-red-500/10 text-red-900 dark:text-red-200"
            enter="transition-all duration-150"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20"
          >
            <p className="block text-base font-semibold leading-none">
              Failed to Save Card
            </p>
            <div className="border-t border-red-200 dark:border-red-700 my-4" />
            We're experiencing difficulties saving your card. Please try another
            card or try again later.
          </Transition>
        ),
        { position: "top-right", id: "nc-product-notify", duration: 3000 }
      );
    }
    return false;
  };
  console.log(Number(user.replace(/\D/g, "")), "user1234");
useEffect(() => {
  const fetchCustomerProfile = async () => {
    try {
      const apiurlone = `/onlineOrder/customer`;
      let responseAdded: AxiosResponse<any, any>;
const baseurl = appConfig.apiPrefix;
      responseAdded = await axios.get(baseurl + apiurlone, {
        headers: {
          MID: merchantname,
          Timezone: getCurrentTimezone(),
        },
        params: {
          customerProfileId: Number(user.replace(/\D/g, "")), // Query parameter
        },
      });

      console.log(responseAdded, "responseAdded");

      if (responseAdded?.data) {
        setUser(responseAdded.data?.customerProfileId);
         localStorage.setItem(
           "user",
           responseAdded.data?.customerProfileId
         );
        setAuth(responseAdded.data?.paymentProfiles);
      }
    } catch (error) {
      console.error("Error fetching customer profile:", error);
    }
  };
  fetchCustomerProfile();
}, [user]);
  console.log(setValue, "auth123444");
  return (
    <div>
      <CommonLayout>
        <div className="flex items-start space-x-4 sm:space-x-6">
          <div className="flex-1">
            <label
              htmlFor="Credit-Card"
              className="flex items-center space-x-4 sm:space-x-6"
            >
              <div className={`p-2.5 rounded-xl border-2 `}>
                <svg
                  className="w-6 h-6 sm:w-7 sm:h-7"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2 12.6101H19"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.25 17.8101H6.96997"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.10986 17.8101H12.5499"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className="font-medium">Debit / Credit Card</p>
            </label>
          </div>
        </div>
        <div className="mt-6 mb-4 space-y-3 sm:space-y-5">
          <div className="p-4 mb-3">
            {auth.length ? (
              <div className="p-4 mb-3">
                {cards.map((card, index) => (
                  <Card
                    key={index}
                    card={card}
                    cardType={card.cardType}
                    cardHolderName={`${card.firstName} ${card.lastName}`}
                    cardNumber={card.cardNumber.replace(/\s/g, "")}
                    
                    onEdit={()=>handleEdit(card)}
                    onDelete={()=>handleDelete(card.profileId)}
                  />
                ))}
                {!showCardField && (
                  <button
                    onClick={handleAddCard}
                    className="mt-4 bg-custom-green text-white font-normal py-2 px-4 rounded"
                  >
                    Add Card
                  </button>
                )}
              </div>
            ) : (
              <>
                <h3 className="text-2xl mt-3 text-center">Card not found</h3>
                <button
                  onClick={handleAddCard}
                  className="mt-4 bg-custom-green text-white font-normal py-2 px-4 rounded"
                >
                  Add Card
                </button>
              </>
            )}
            {(showCardField||formVisible) && (
              <form>
                <PaymentForm
                  control={control}
                  errors={errors}
                  handleAddCard={handleAddCard}
                  // setValue={setValue}
                  validateCardNumber={validatecardNumber}
                  validateData={validateDate}
                />
                <BillingAddressForm
                  control={control}
                  errors={errors}
                 
                  setValue={setValue}
                  clearErrors={clearErrors}
                  billingCity={billingCity}
                  billingAddressInfo={billingAddressInfo}
                  billingZip={billingZip}
                  billingState={billingState}
                  billingCountry={billingCountry}
                  namespace={"billingAddress"}
                />
                <button
                  type="button"
                  onClick={() => handleSubmit(handleSaveCard)()}
                  className="mt-4 bg-blue-500 text-white font-normal py-2 px-4 rounded"
                >
                  Save
                </button>
              </form>
            )}
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountSavelist;
