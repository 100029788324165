import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import logoLightImg from "images/logo-light.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  const [logo, setLogo] = React.useState<string | null>(null);
  const [merchantName, setMerchantName] = React.useState<string | null>(null);
  useEffect(() => {
    const fetchLogo = async () => {
      const merchantname = localStorage.getItem("merchantname");
      setMerchantName(merchantname);
      const logoo = localStorage.getItem("onlineOrderSettingsLogo");
      setLogo(logoo);
    };

    if (!logo) {
      const delay = setTimeout(() => {
        fetchLogo();
      }, 1000); 
      return () => clearTimeout(delay); 
    }
  }, [logo]);
  
  console.log(logo,localStorage.getItem("onlineOrderSettingsLogo"),"logo")
  return (
    <Link
      to={`/${merchantName}`}
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {logo ? (
        <img
          className={`block max-h-16 sm:max-h-14 ${
            logo ? "dark:hidden" : ""
          } `}
          src={logo}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
    </Link>
  );
};

export default Logo;
